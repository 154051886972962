import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import ViewSalesInvoice from './ViewSalesInvoice';
import UpdateSalesInvoice from './UpdateInvoice/UpdateSalesInvoice';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePurchase } from '../../../features/PurchaseSlice/PurchaseSlice';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

const SalesList = () => {
    const [sales, setSales] = useState([]);
    const [purchaseModal, setPurchaseModal] = useState(null);
    const [updateModal, setUpdateModal] = useState(null);
    const dispatch = useDispatch();

    const date = new Date();
    const today = format(date, "yyyy-MM-dd")

    useEffect(() => {
        fetch('https://mntnagadserver.clearsoftwares.xyz/getsales')
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(d => d.salesDate === today);
                setSales(filter);

            })
    }, [])


    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        console.log(startDate, endDate);
        const url = `https://mntnagadserver.clearsoftwares.xyz/filteredsales?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                // setIsLoaded(true)
                setSales(data);
                console.log(data);
            })


    }

    const handleAddProductToInvoice = (sales) => {
        setPurchaseModal(sales)
    }
    const handleUpdatePurchase = (sales) => {
        dispatch(updatePurchase(sales));

    }

    const handleDeleteProduct = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://mntnagadserver.clearsoftwares.xyz/getsales/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                })
        }
    };

    const totalPayment = sales.reduce((total, currentValue) => total + parseInt(currentValue?.payment), 0);
    const totalAmount = sales.reduce((total, currentValue) => total + parseInt(currentValue?.grandTotal), 0);
    const totalDue = sales.reduce((total, currentValue) => total + parseInt(currentValue?.dueAmount), 0);
    return (
        <div className='w-full px-12 pb-5 my-5'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Manage Sales</h1>
            <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-right'>
                <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                    <label class="label">
                        <span class="label-text">Starting Date</span>
                    </label>
                    <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                    <label class="label">
                        <span class="label-text">End Date</span>
                    </label>
                    <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                </div>
                <input type='submit' value='Search' className='btn btn-md btn-error text-white mt-4 lg:mt-9 lg:ml-2' />
            </form>
            <table class="table w-1/2 mx-auto mt-8 mb-20">
                <thead className='text-center'>
                    <tr>
                        <th className='bg-red-600 text-white normal-case'>SL#</th>
                        <th className='bg-red-600 text-white normal-case'>Sales Date</th>
                        <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                        <th className='bg-red-600 text-white normal-case'>Customer</th>
                        <th className='bg-red-600 text-white normal-case'>Total Amount</th>
                        <th className='bg-red-600 text-white normal-case'>Total Payment</th>
                        <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        sales?.map((sale, index) => <tr className=''>
                            <td>{index + 1}</td>
                            <td>{sale.salesDate}</td>
                            <td><label for="view-modal" onClick={() => handleAddProductToInvoice(sale)} style={{ width: '300px', height: '50px' }} className={`btn btn-link cursor-pointer`}>{sale?.salesInvoice}</label></td>
                            <td>{sale.person_name ? sale.person_name : "Walking Customer"}</td>
                            <td>{sale.grandTotal}</td>
                            <td>{sale.payment}</td>
                            <td>{sale.dueAmount}</td>
                            <td><Link to={`/managesales/edit/${sale._id}`} onClick={() => handleUpdatePurchase(sale)} className='btn btn-info btn-xs'><FaEdit /></Link></td>
                            <td><button onClick={() => handleDeleteProduct(sale._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>
                        </tr>)
                    }

                </tbody>
                <tfoot className='mb-12 text-center'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='text-lg'>Total</td>
                    <td className='text-lg ml-4'>{totalAmount}</td>
                    <td className='text-lg'>{totalPayment}</td>
                    <td className='text-lg'>{totalDue}</td>
                    <td></td>
                    <td></td>
                </tfoot>
            </table>
            <div className='w-8/12'>
                {
                    purchaseModal && <ViewSalesInvoice setPurchaseModal={setPurchaseModal} purchaseModal={purchaseModal}></ViewSalesInvoice>
                }
            </div>
            <div className='w-8/12'>
                {
                    updateModal && <UpdateSalesInvoice setUpdateModal={setUpdateModal} updateModal={updateModal}></UpdateSalesInvoice>
                }
            </div>
        </div>
    );
};

export default SalesList;