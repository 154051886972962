import logo from '../../assets/images/logo.jpeg'

const BusinessInfo = {
    name: "Nazmul Traders",
    fullName: "M/S Nazmul Traders Nagad Distributor",
    dealer: "(Authorized Dealer Of Nagad Bangladesh)",
    address: "Valuka, Mymensingh",
    mobile: "Mobile: 01713-518387",
    img: logo,
}

export default BusinessInfo;
