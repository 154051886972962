import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

const CashStatement = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [cashstatement, setCashStatement] = useState([]);
    const [opening, setOpening] = useState(0);
    const [dates, setDates] = useState('');
    const [isFilter, setIsFilter] = useState(false);

   
    console.log(dates);
    const date = new Date();
    const today = format(date, "yyyy-MM-dd")

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        console.log(startDate, endDate);
        const url = `https://mntnagadserver.clearsoftwares.xyz/filteredcashstatement?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setCashStatement(data);
                setIsFilter(true);
                setDates(startDate + " to " + endDate);
            })
        const url2 = `https://mntnagadserver.clearsoftwares.xyz/filteredopeningbalance?startDate=${startDate}`
        fetch(url2)
            .then(res => res.json())
            .then(data => {
                const totalIn = data.reduce((total, currentValue) => total + (currentValue.totalIncomes || 0), 0);
                const totalOut = data.reduce((total, currentValue) => total + (currentValue.totalExpenses || 0), 0);
                const finalBalance = totalIn - totalOut;
                setOpening(finalBalance);
            })
    }

    useEffect(() => {
        fetch("https://mntnagadserver.clearsoftwares.xyz/todaycashstatement")
            .then(res => res.json())
            .then(data => {
                setCashStatement(data);
                setIsLoaded(true);
            })
    }, [])
    useEffect(() => {
        fetch("https://mntnagadserver.clearsoftwares.xyz/openingbalance")
            .then(res => res.json())
            .then(data => {
                const totalIn = data.reduce((total, currentValue) => total + (currentValue.totalIncomes || 0), 0);
                const totalOut = data.reduce((total, currentValue) => total + (currentValue.totalExpenses || 0), 0);
                const finalBalance = totalIn - totalOut;
                setOpening(finalBalance);

            })
    }, [])


    const totalIncome = cashstatement.reduce((total, currentValue) => total + parseInt(currentValue.income), 0);
    const totalExpense = cashstatement.reduce((total, currentValue) => total + parseInt(currentValue.expense), 0);

    

    return (
        <div className='mx-2'>
            <h2 className='mt-8 text-center text-lg font-bold'>Daily Cash Statement Report ({isFilter === true ? dates : today})</h2>
            <div className='flex flex-col lg:flex-row justify-between lg:items-center'>
                <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-right'>
                    <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">Starting Date</span>
                        </label>
                        <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">End Date</span>
                        </label>
                        <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <input type='submit' value='Search' className='btn btn-md btn-error text-white w-80 mx-auto lg:w-20 mt-4 lg:mt-9 lg:ml-2' />
                    <div>
                    </div>
                </form>
                <div className='lg:mt-28 mt-0 text-right'>
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-error text-white btn-xs mt-10 lg:mt-0"
                        table="table-to-xls"
                        filename="cashstatement-table"
                        sheet="cashstatement-sheet"
                        buttonText="Export to exel" />
                </div>
            </div>
            <div className='mb-20'>
                <div class="overflow-x-auto mt-8 text-xs">
                    <table id='table-to-xls' class="table w-full text-center shadow-xl rounded-lg">
                        {
                            isLoaded && <thead>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white w-16 truncate'>Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                    <th className='bg-red-600 text-white normal-case'>Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Type</th>
                                    <th className='bg-red-600 text-white normal-case'>Income Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Expense Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Details</th>
                                </tr>

                            </thead>
                        }
                        <tbody>
                            {
                                cashstatement.map((income, index) => <tr>
                                    <td className='border border-red-600'>{index + 1}</td>
                                    <td className='border border-red-600 w-16 truncate'>{income.date}</td>
                                    <td className='border border-red-600'>{income.invoice}</td>
                                    <td className='border border-red-600'>{income.name}</td>
                                    <td className='border border-red-600'>{income.type}</td>
                                    <td className='border border-red-600'>{income.income === 0 ? "-" : income.income}</td>
                                    <td className='border border-red-600'>{income.expense === 0 ? "-" : income.expense}</td>
                                    <td className='border border-red-600'>{income.details}</td>
                                </tr>)
                            }

                        </tbody>
                        {
                            isLoaded && <tfoot>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white w-16 truncate'></th>
                                    <th className='bg-red-600 text-white text-sm normal-case'>Opening Balance</th>
                                    <th className='bg-red-600 text-white text-sm'>{opening}</th>
                                    <th className='bg-red-600 text-white text-sm normal-case'>Total</th>
                                    <th className='bg-red-600 text-white text-sm'>{totalIncome}</th>
                                    <th className='bg-red-600 text-white text-sm'>{totalExpense}</th>
                                    <th className='bg-red-600 text-white text-sm normal-case'>Closing: {opening + totalIncome - totalExpense}</th>
                                </tr>
                            </tfoot>
                        }
                    </table>
                </div>

            </div>
        </div>
    );
};

export default CashStatement;