import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import ViewSalesInvoice from './ViewSalesInvoice';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePurchase } from '../../../../features/PurchaseSlice/PurchaseSlice';
import { format } from 'date-fns';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

const InvoiceWiseProfit = () => {
    const [sales, setSales] = useState([]);
    const [purchaseModal, setPurchaseModal] = useState(null);
    const [updateModal, setUpdateModal] = useState(null);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();

    const date = new Date();
    const today = format(date, "yyyy-MM-dd")

    useEffect(() => {
        fetch(`https://mntnagadserver.clearsoftwares.xyz/invoicewiseprofit`)
            .then(res => res.json())
            .then(data => {
                const filter = data.filter(d => d.salesDate === today);
                setSales(filter);
                console.log(filter);

            })
    }, [])


    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        console.log(startDate, endDate);
        const url = `https://mntnagadserver.clearsoftwares.xyz/invoicewiseprofit?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                // setIsLoaded(true)
                setSales(data);
                console.log(data[0]);
            })


    }

    const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            console.log(formattedDate);


    const handleAddProductToInvoice = (sales) => {
        setPurchaseModal(sales)
    }
    const handleUpdatePurchase = (sales) => {
        dispatch(updatePurchase(sales));

    }


    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        const match = sales.filter(sale => sale.salesCustomer.includes(searchText) || sale.salesCustomer.toLowerCase().includes(searchText)
            || sale.salesCustomer.toUpperCase().includes(searchText) || sale.salesInvoice.includes(searchText)
            || sale.salesInvoice.toLowerCase().includes(searchText) || sale.salesInvoice.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }


    const totalPayment = isSearching?searchResult?.reduce((total, currentValue) => total + parseInt(currentValue?.payment), 0): sales.reduce((total, currentValue) => total + parseInt(currentValue?.payment), 0);
    const totalAmount = isSearching? searchResult?.reduce((total, currentValue) => total + parseInt(currentValue?.grandTotal), 0): sales.reduce((total, currentValue) => total + parseInt(currentValue?.grandTotal), 0);
    const totalDue = isSearching?searchResult?.reduce((total, currentValue) => total + parseInt(currentValue?.dueAmount), 0): sales.reduce((total, currentValue) => total + parseInt(currentValue?.dueAmount), 0);
    const totalCost = isSearching?searchResult?.reduce((total, currentValue) => total + parseInt(currentValue?.totalCost), 0): sales.reduce((total, currentValue) => total + parseInt(currentValue?.totalCost), 0);
    return (
        <div className='w-full px-4 pb-5 my-5'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Invoice Wise Profit/Loss</h1>
            <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center lg:items-right'>
                <div class="form-control w-full lg:w-40 max-w-xs mx-auto lg:mx-0 lg:mr-2">
                    <label class="label">
                        <span class="label-text">Starting Date</span>
                    </label>
                    <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-full lg:w-40 max-w-xs mx-auto lg:mx-0 lg:mr-2">
                    <label class="label">
                        <span class="label-text">End Date</span>
                    </label>
                    <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                </div>
                <input type='submit' value='Search' className='btn btn-md btn-error text-white mt-4 lg:mt-9 lg:ml-2' />
            </form>
            <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Invoice Wise Profit Sheet`}
                    sheet={`sales-sheet`}
                    buttonText="Export to exel" />
                {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
            </div>
            <div className='flex justify-end items-center my-4'>
                <div class="form-control w-60">
                    <input onChange={handleSearchResult} type="text" placeholder="Search Sales" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                </div>
            </div>
            <div className='overflow-auto'>
            <table id='table-to-xls' class="table w-1/2 mx-auto mt-8 mb-20 text-xs">
                <thead className='text-center'>
                    <tr>
                        <th className='bg-red-600 text-white normal-case'>SL#</th>
                        <th className='bg-red-600 text-white normal-case'>Sales Date</th>
                        <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                        <th className='bg-red-600 text-white normal-case'>Total Amount</th>
                        <th className='bg-red-600 text-white normal-case'>Total Payment</th>
                        <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                        <th className='bg-red-600 text-white normal-case'>Profit</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    { isSearching?searchResult?.map((sale, index) => <tr className=''>
                            <td>{index + 1}</td>
                            <td>{sale.salesDate}</td>
                            <td><label for="view-modal" onClick={() => handleAddProductToInvoice(sale)} style={{ width: '150px', height: '50px' }} className={`text-blue-600 font-bold cursor-pointer`}>{sale?.salesInvoice}</label></td>
                            <td>{sale.grandTotal}</td>
                            <td>{sale.payment}</td>
                            <td>{sale.dueAmount}</td>
                            <td>{sale?.grandTotal-sale?.totalCost}</td>
                        </tr>):
                        sales?.map((sale, index) => <tr className=''>
                            <td>{index + 1}</td>
                            <td>{sale.salesDate}</td>
                            <td><label for="view-modal" onClick={() => handleAddProductToInvoice(sale)} style={{ width: '150px', height: '50px' }} className={`text-blue-600 font-bold cursor-pointer`}>{sale?.salesInvoice}</label></td>
                            <td>{sale.grandTotal}</td>
                            <td>{sale.payment}</td>
                            <td>{sale.dueAmount}</td>
                            <td>{sale?.grandTotal-sale?.totalCost}</td>
                        </tr>)
                    }

                </tbody>
                <tfoot className='mb-12 text-center'>
                    <td></td>
                    <td></td>
                    <td className='text-sm'>Total</td>
                    <td className='text-sm ml-4'>{totalAmount}</td>
                    <td className='text-sm'>{totalPayment}</td>
                    <td className='text-sm'>{totalDue}</td>
                    <td className='text-sm'>{totalAmount-totalCost}</td>
                </tfoot>
            </table>
            </div>
            <div className='w-8/12'>
                {
                    purchaseModal && <ViewSalesInvoice setPurchaseModal={setPurchaseModal} purchaseModal={purchaseModal}></ViewSalesInvoice>
                }
            </div>
        </div>
    );
};

export default InvoiceWiseProfit;