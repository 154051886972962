import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../assets/images/logo.jpeg';
import BusinessInfo from '../../../Shared/BusinessInfo';

// import { ComponentToPrint } from './ComponentToPrint';


const ViewInvoice2 = ({ purchaseModal2, setPurchaseModal2 }) => {
    const componentRef = useRef();
    const { totalAmount, products, date, invoice, currentTime } = purchaseModal2;
    console.log(purchaseModal2);


    const closeModal = () => {
        setPurchaseModal2(null);
    }



    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Purchase Inovice No-${invoice}`,

    })
    const options = {
        timeZone: 'Asia/Dhaka',
        hour12: false,
    };
    const currentTime2 = new Date().toLocaleString('en-US', options);

    return (
        <>
            <div className="z-50">
                <input type="checkbox" id="view-modal-2" class="modal-toggle" />
                <div class="modal mt-12">
                    <div class="modal-box max-w-none w-full lg:w-10/12 lg:ml-64 relative">
                        <div className='text-right'>
                            <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
                        </div>
                        <div ref={componentRef}>
                            <div className='flex justify-center items-center mt-8'>
                    <div>
                        <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                    </div>
                    <div className='lg:mr-40'>
                        <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                        <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                        <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                        <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                    </div>
                </div>
                            <div class="flex items-center justify-between">
                                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                            </div>
                            <div class="flex items-center justify-between">
                                <hr class="border-b border-gray-300 w-full my-px" />
                                <hr class="border-b border-gray-300 w-full my-px" />
                            </div>
                            <label for="view-modal-2" onClick={closeModal} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                            <h3 class="text-lg uppercase text-center font-bold mt-8">Purchase Order Invoice (Supplier Copy)</h3>
                            <div className='text-left ml-20 text-xs'>
                                <p><span style={{ marginRight: '18px' }} className='font-bold'>Purchase Order No:</span>{invoice}</p>
                                <p><span style={{ marginRight: '30px' }} className='font-bold'>Order Date-Time:</span>  {currentTime}</p>
                                <p><span style={{ marginRight: '80px' }} className='font-bold'>Print Time:</span>  {currentTime2}</p>
                            </div>
                            <div className='overflow-auto'>
                            <table class="table w-1/2 mt-8 mx-auto text-xs ">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'>SL#</th>
                                        <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                        <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                        <th className='bg-red-600 text-white normal-case'>Order Qyt</th>
                                        <th className='bg-red-600 text-white normal-case'>Unit</th>
                                        <th className='bg-red-600 text-white normal-case'>Rate Per Unit</th>
                                        <th className='bg-red-600 text-white normal-case'>Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center border '>
                                    {
                                        products.map((product, index) => <tr className=''>
                                            <td className='border-red-600 border-1'>{index + 1}</td>
                                            <td className='border-red-600 border-1'>{product.code}</td>
                                            <td className='border-red-600 border-1'>{product.name}</td>
                                            <td className='text-red-600 font-bold border-red-600 border-1'>{product.quantity}</td>
                                            <td className='text-red-600 font-bold border-red-600 border-1'>{product.unit}</td>
                                            <td className='border-red-600 border-1'></td>
                                            <td className='border-red-600 border-1'></td>
                                        </tr>)
                                    }

                                </tbody>
                                <tbody className='text-center bg-white'>
                                    <tr className='border-t-2 border-black'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td ></td>
                                        <td >Total</td>
                                        <td className='font-bold'></td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            <div className='flex justify-between mt-40 text-xs'>
                                <h3 className='border-t-2 border-gray-400 py-2 inline-block lg:ml-40 font-bold'>Authorized Sign</h3>
                                <h3 className='border-t-2 border-gray-400 py-2 inline-block lg:mr-40 font-bold'>Manager Sign</h3>
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </>
    );
};

export default ViewInvoice2;